import React from "react";
import Container from "../components/container";
import Layout from "../components/layouts/main-layout";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/seo";

const Standort = () => {
  return (
    <Layout>
      <Seo
        title="Standort Surfschule Langeoog"
        description="Der Standort der Surfschule Langeoog - Tidens Surfhuus - befindet sich am Weststrand der ostfriesischen Nordseeinsel Langeoog."
      />
      <StaticImage
        src="../images/heroimages/tidens-spot-header.jpg"
        alt="Tidens Surfhuus Standort auf Langeoog"
        width={2048}
        height={680}
        quality={90}
        className="w-full h-[35vw] min-h-[25vw] max-h-[50vh]"
        objectFit="cover"
      />
      <div className="bg-secondary rounded-t-3xl relative top-[-1.5rem]">
        <Container>
          <h1>Die Nordseeinsel Langeoog</h1>
          <p>
            Unsere autofreie ostfriesische Insel Langeoog, gelegen im
            Nationalpark Niedersächsisches Wattenmeer in der Nordsee, bietet
            ideale Bedingungen für eine Vielzahl von Wassersportarten. Je nach
            Tide stehen uns auf der Insel verschiedene Stehreviere und
            Flachwasserspots für Anfänger, Aufsteiger und Fortgeschrittene zur
            Verfügung. Kitesurfer, Wingsurfer/-foiler, Windsurfer und
            Stand-Up-Paddler – für alle gibt es hier hervorragende Bedingungen
            zur Ausübung ihres Sports. Je nach Windrichtung und Stärke gibt es
            auf Langeoog sogar gute Wellen zum Wellenreiten. Und auch die
            fortgeschrittenen Wind-, Kite- und Wingsurfer kommen hier auf ihre
            Kosten. Die Windwahrscheinlichkeit auf Langeoog ist hoch, da der
            Wind hier immer etwas kräftiger weht als am Festland.
          </p>
          <p>
            Unsere Station findest du am Westrand von Langeoog am Übergang
            Ullis Kiosk / Katholische Kirche. Hier finden alle unsere Kurse statt.
          </p>
          <h2>Die Spots</h2>
          <h3>Spot Langeoog Weststrand</h3>
          <p>
            Unser Schulungsstandort am Übergang Ullis Kiosk / Katholische Kirche
            bietet bei Niedrigwasser ein tolles Flachwasserrevier und bei
            Hochwasser kleine bis moderate Wellen für alle Anfänger und
            Fortgeschrittene. Hier finden alle unsere Kurse statt. Ein toller
            Spot, der durch den feinen weitläufigen Sandstrand und unsere neue
            Surfstation abgerundet wird.
          </p>
          <h3>Spot Langeoog Oststrand</h3>
          <p>
            Am Übergang Gerk-sin-Spoor befindet sich unser Wellenspot für
            fortgeschrittene Wellenreiter oder fortgeschrittene Windsportler
            (Welleneinsteiger) im Bereich Windsurfen, Kitesurfen und sogar
            Wingfoilen. Der Spot ist nicht weit vom Hauptstrand entfernt und
            somit ganz einfach zu erreichen. Hier findest du tolle Wellen, einen
            schönen Strand und eine coole Atmosphäre.
          </p>
          <h2>Spoteinweisung / Sicherheit</h2>
          <p>
            Du hast dein eigenes Material mitgebracht und surfst eigenständig,
            kennst den Spot aber noch nicht? Melde dich bitte vor deiner ersten
            Session bei uns, damit wir dich über die Besonderheiten des Spots
            informieren können. So steht einem sicheren Surferlebnis nichts mehr
            im Wege.
          </p>
        </Container>
      </div>
    </Layout>
  );
};

export default Standort;
